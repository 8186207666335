import React from "react";

import { ContactForm } from "../components/ContactForm";
//import MapContainer  from "../components/Maps";
import { RandomTestimonial } from "../components/RandomTestimonial";

export const Contact = () => {

    return(
        <>
        <div className="container-xl py-5">
            <div className="spacer-half"/>
            <h1 className="display-4">Contact Us</h1>
            <div className="jumbotron my-5 px-5">
                <div className="row">
                    <div className="col-12 col-md-6 my-5 align-items-center contactInfo">
                        <div className="row justify-content-center">
                            <img src="./images/contactImage.png" id="contactImage" alt="racing diffs" width="480px" height="300px" />
                        </div>
                        <div className="row my-3 justify-content-center">
                            <a href="tel:0296048887" className="col-auto" id="contactLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-telephone" id="contactPhone"></i></a>
                            <a href="sms://+61411072066" className="col-auto" id="contactLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-chat-dots-fill" id="contactSMS"></i></a>
                            <a href="mailto:anthony@diffandgear.com.au" className="col-auto" id="contactLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-envelope" id="contactMail1"></i></a>
                            <a href="https://www.facebook.com/SmithfieldDiffandGearbox" className="col-auto" id="contactLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook" id="contactFB"></i></a>
                            <a href="https://www.instagram.com/smithfielddiffgear/" className="col-auto" id="contactLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram" id="contactInsta"></i></a>
                        </div>
                        <div className="row my-3 justify-content-center">
                        <a href="tel:0296048887" className="col-auto" id="addressLink" target="_blank" rel="noopener noreferrer">(02) 9604 8887</a>
                        </div>
                        <div className="row my-3 justify-content-center">
                            <a href="https://www.google.com.au/maps/place/Smithfield+Diff+%26+Gearbox/@-33.8474323,150.9310133,17z/data=!3m1!4b1!4m5!3m4!1s0x6b1297c7b03d3c15:0xff58d9bb61e42b6d!8m2!3d-33.8474368!4d150.933202?hl=en-GB&authuser=0" className="col-auto" id="addressLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-geo-alt-fill" id="contactLink"></i> 83 Market St Smithfield NSW 2164</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-5">
                        <ContactForm />
                    </div>
                </div>
            </div>
            <div className="spacer" />
            <div className="row">
                <RandomTestimonial />
            </div>
        </div>
        {/* <div className="container-fluid" id="mapsContainer">
            <MapContainer />
    </div>
    <div className="spacer" /> */ }
        </>
    );
}