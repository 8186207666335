import React from "react";
import partners from "../data/Partners";

export const Partners = () => {

    return(
        <div className="container-xl my-5">
            <h1 className="display-4">Our Partners/Suppliers</h1><div className="form-text mx-3">We only use quality products from these suppliers</div>
            <hr className="my-4" />
            <div className="spacer"/>
            <div className="justify-content-center align-items-center row" id="partnerDiv">
                {partners && partners.map(({partner, image, alt}) => (
                    <div key={partner} className="col">
                        <img className="d-block mx-auto partners" src={image} alt={alt} width="200px" height="123px" />
                    </div>
                ))}
            </div>
        </div>
    );
}