import React, { useEffect, useState } from "react";
import { RandomTestimonial } from "../components/RandomTestimonial";

export const Calculations = () => {

    const [crown, setCrown] = useState("");
    const [pinion, setPinion] = useState("");
    const [ratio, setRatio] = useState("");

    const [newRatio, setNewRatio] = useState("");
    const [oldRatio, setOldRatio] = useState("");
    const [revs, setRevs] = useState("");
    const [changeRatio, setChangeRatio] = useState("");

    useEffect(() => {
        let firstCalc = newRatio - oldRatio;
        let secondCalc = firstCalc / newRatio;
        let finalCalc = secondCalc * revs;
        let finalRevs = parseFloat(revs) + parseFloat(finalCalc);
        setChangeRatio(finalRevs);
    }, [newRatio, oldRatio, revs]);

    useEffect(() => {
        let calcRatio = crown / pinion;
        setRatio(calcRatio);
    }, [crown, pinion]);

    return(
        <div className="container-xl py-5" id="calculationsBackdrop">
            <div className="spacer-half"/>
            <h1 className="display-4">Calculations</h1>
            <div className="row">
                <div className="col-12 col-md-6 my-5">
                    <div className="card">
                        <div className="card-header">
                            Ratio Calulator
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row align-items-center">
                                    <div className="form-floating mb-3">
                                        <input className="form-control" type="number" name="crown" value={crown} onChange={(e) => setCrown(e.target.value)} required />
                                        <label className="form-label">Crown Wheel Teeth</label>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="form-floating mb-3">
                                        <input className="form-control" type="number" name="pinion" value={pinion} onChange={(e) => setPinion(e.target.value)} required />
                                        <label className="form-label">Pinion Teeth</label>
                                    </div>
                                </div>
                                <div className="spacer-calc"/>
                                <p className="card-text"><small className="text-muted">Note: Calulator is only a guideline and may not be 100% correct</small></p>
                            </form>
                        </div>
                        {ratio ? (
                            <div className="card-footer text-muted text-center">
                                Your Ratio is <div className="fw-bold">{ratio}</div>
                            </div>):("")
                        }
                    </div>
                </div>
                <div className="col-12 col-md-6 my-5">
                    <div className="card">
                        <div className="card-header">
                            Ratio Change Calulator
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row align-items-center">
                                    <div className="form-floating mb-3">
                                        <input className="form-control" type="number" name="newRatio" value={newRatio} onChange={(e) => setNewRatio(e.target.value)} required />
                                        <label className="form-label">New Ratio</label>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="form-floating mb-3">
                                        <input className="form-control" type="number" name="oldRatio" value={oldRatio} onChange={(e) => setOldRatio(e.target.value)} required />
                                        <label className="form-label">Old Ratio</label>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="form-floating mb-3">
                                        <input className="form-control" type="number" name="revs" value={revs} onChange={(e) => setRevs(e.target.value)} required />
                                        <label className="form-label">Revs at 100km/h</label>
                                    </div>
                                </div>
                                <p className="card-text"><small className="text-muted">Note: Calulator is only a guideline and may not be 100% correct</small></p>
                            </form>
                        </div>
                        {changeRatio ? (
                            <div className="card-footer text-muted text-center">
                                Revs with new ratio at 100km/h is <div className="fw-bold">{changeRatio}</div>
                            </div>):("")
                        }
                    </div>
                </div>
            </div>
            <div className="d-block mx-auto">
                <img className="d-block mx-auto" id="calcImage" src="/images/calculations.png" alt="Smithfield Diff and Gearbox" width="900px" height="608.82px" />
            </div>
            <div className="row">
                <RandomTestimonial />
            </div>
        </div>
    );
}