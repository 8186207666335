export const GenuineParts = [
    {
        partner: "Ford",
        image: "./images/parts/fordLogo.png",
        alt: "Ford Genuine Parts"
    },
    {
        partner: "Honda",
        image: "./images/parts/hondaLogo.png",
        alt: "Honda Genuine Parts"
    },
    {
        partner: "Isuzu",
        image: "./images/parts/isuzuLogo.png",
        alt: "Isuzu Genuine Parts"
    },
    {
        partner: "Kia",
        image: "./images/parts/kiaLogo.png",
        alt: "Kia Genuine Parts"
    },
    {
        partner: "Mazda",
        image: "./images/parts/mazdaLogo.png",
        alt: "Mazda Genuine Parts"
    },
    {
        partner: "Mitsubishi",
        image: "./images/parts/mitsubishiLogo.png",
        alt: "Mitsubishi Genuine Parts"
    },
    {
        partner: "Nissan",
        image: "./images/parts/nissanLogo.png",
        alt: "Nissan Genuine Parts"
    },
    {
        partner: "Subaru",
        image: "./images/parts/subaruLogo.png",
        alt: "Subaru Genuine Parts"
    },
    {
        partner: "Suzuki",
        image: "./images/parts/suzukiLogo.png",
        alt: "Suzuki Genuine Parts"
    },
    {
        partner: "Toyota",
        image: "./images/parts/toyotaLogo.png",
        alt: "Toyota Genuine Parts"
    },
];
export default GenuineParts;
