import React, {useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

import { NavBar } from "./components/NavBar";
import { Footer } from './components/Footer';

import { Home } from "./sections/Home";
import { Gallery } from "./sections/Gallery";
import { Cars } from "./sections/Cars";
import { Calculations } from "./sections/Calculations";
import { Contact } from "./sections/Contact";


const App = () => {
  
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const [isHome, setIsHome] = useState(false);

  return (
    <>
      <div className="root">
        <BrowserRouter>
          { !isHome &&
            <NavBar />
          }

          <Routes>
            <Route exact path="/" element={<Home showNav={setIsHome}/>} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Cars" element={<Cars />} />
            <Route path="/Calculations" element={<Calculations />} />
            <Route path="/Contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}

export default App;
