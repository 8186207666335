import React from "react";
import { Racing } from "../components/Racing";
import { RandomTestimonial } from "../components/RandomTestimonial";
import cars from "../data/Racecars";

export const Cars = () => {
    
    return(
        <div className="container-xl py-5">
            <div className="spacer-half"/>
            <h1 className="display-4">Racing Cars</h1>
            <div className="row">
                {cars && cars.map(({image, title, category, driver, differential, fastestET, fastestMPH, website}) => (
                    <div key={title} className="col-12 col-md-4 my-5">
                        <Racing image={image} title={title} category={category} driver={driver} differential={differential} fastestET={fastestET} fastestMPH={fastestMPH} website={website} />
                    </div>
                ))}
            </div>
            <div className="d-block mx-auto">
                <img className="d-block mx-auto" id="calcImage" src="/images/racing.png" alt="Smithfield Diff and Gearbox" width="900px" height="608.82px" />
            </div>
            <div className="row">
                <RandomTestimonial />
            </div>
        </div>
    );
}