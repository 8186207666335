import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';

export const NavBar = () => {

    const [mNavbar, setMnavbar] = useState("");
    const [mWidth, setMwidth] = useState("");
    const [mHeight, setMheight] = useState("");
    const [mLink, setMlink] = useState("");
    const [mRight, setMRight] = useState("");

    useEffect(() => {
        if(window.outerWidth < 800) {
            setMnavbar("px-1");
            setMwidth("137.5px");
            setMheight("50px");
            setMlink("");
        } else {
            setMnavbar("px-4");
            setMwidth("165px");
            setMheight("60px");
            setMlink("mx-2");
        }

        if(window.outerWidth < 992) {
            setMRight("");
        } else {
            setMRight("ms-auto");
        }
    }, []);



    return(
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" sticky="top" className={mNavbar} id="navContainer" style={{backgroundColor: '#222222'}}>
                <Navbar.Brand>
                    <Nav.Link className={mLink} href="/"><img id="storeLogo" alt="diffandgear" src="/images/siteLogo.png" width={mWidth} height={mHeight} /></Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle id="main-navigation-toggle" />
                <Navbar.Offcanvas id="responsive-navbar-nav" placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="siteLogo">
                            <img id="offCanvasLogo" alt="diffandgear" src="/images/siteLogo.png" width={mWidth} height={mHeight} />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Nav className={mRight} id="overlay-content">
                        <Nav.Link className="mx-2" href="/">Home</Nav.Link>
                        <Nav.Link className="mx-2" href="https://www.ebay.com.au/str/smithfielddiffandgearbox?mkcid=16&mkevt=1&mkrid=705-154756-20017-0&ssspo=thz7wjh-q62&sssrc=3418065&ssuid=ISzhptwHSGi&widget_ver=artemis&media=COPY&_tab=aboutt">Shop</Nav.Link>
                        <Nav.Link className="mx-2" href="/Gallery">Gallery</Nav.Link>
                        <Nav.Link className="mx-2" href="/Cars">Racing Cars</Nav.Link>
                        <Nav.Link className="mx-2" href="/Calculations">Calculations</Nav.Link>
                        <Nav.Link className="mx-2" href="/Contact">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Offcanvas>
            </Navbar>
        </>
    );
}