import React from "react";
import GenuineParts from "../data/GenuineParts";

export const Parts = () => {
    return(
        <div className="container-xl my-5">
            <h1 className="display-4">Genuine Parts</h1><div className="form-text mx-3">We use Genuine Parts from these manufacturers</div>
            <hr className="my-4" />
            <div className="spacer"/>
            <div className="justify-content-center align-items-center row">
                {GenuineParts && GenuineParts.map(({partner, image, alt}) => (
                    <div key={partner} className="col">
                        <img className="d-block mx-auto partners" src={image} alt={alt} width="200px" height="123px" />
                    </div>
                ))}
            </div>
        </div>
    );
}