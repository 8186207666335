import React from 'react';
import { Parts } from './Parts';
import { Partners } from './Partners';
import { Testimonials } from './Testimonials';
import { Shop } from './Shop';
import { Services } from './Services';

export const About = () => {
    return(
        <div className="container-xl my-5">
            <div className="row">
                <div className="spacer"/>
                <div className="col-12 col-md-8 vertical-center">
                    <div className="jumbotron">
                        <h1 className="display-4">Smithfield Diff & Gear</h1>
                        <p className="lead">Our experience is the DIFFerence</p>
                        <hr className="my-4" />
                        <p>Smithfield Diff & Gearbox Specialist was started by Pasquale Lavorato in 1976. It specializes in differentials, manual gearboxes and clutches from cars to small trucks.</p>
                        <p>The business has grown over the years and is now run by sons Raffaele and Anthony. Smithfield Diff & Gearbox now houses it's own machine shop, offering flywheel machining, anything from standard flywheels to race car clutches.</p>
                        <p>We also have our own Lathe where we can re-sleeve and repair worn out shafts to eliminate the need to purchase a new one.</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="row my-1 vertical-center">
                        <img src="/images/abt-1.jpeg" alt="smithfield diff and gear" id="about1" width="250px" height="170px"/>
                    </div>
                    <div className="row my-1 vertical-center">
                        <img src="/images/3cars.jpeg" alt="smithfield diff and gear" id="about2" width="670px" height="500px"/>
                    </div>
                </div>
                <div className="spacer"/>
            </div>
            <div className="row">
            <div className="spacer"/>
                <span className="d-flex col-12 col-md-6 justify-content-center align-items-center my-4">
                    <h2 className="text-center align-middle">We have proudly been a Capricorn Preferred Supplier for over 12 years</h2>
                </span>
                <div className="col-12 col-md-6 my-4">
                    <img className="d-block mx-auto preferred" src="./images/suppliers/preferredsupplier.png" alt="Capricorn Preferred Supplier" width="400px" height="285px"/>
                </div>
                <div className="spacer"/>
            </div>
            <div className="row">
                <Services />
            </div>
            {/*<div className="row">
                <Shop />
    </div>*/}
            <div className="row">
                <Testimonials />
            </div>
            <div className="row">
                <div className="spacer"/>
                <Partners />
                <div className="spacer"/>
                <Parts />
                <div className="spacer" />
            </div>
        </div>
    );
}