export const cars = [
    {
        image: "./images/racing/Newby.jpg", 
        title: "JBS/Newby Racing", 
        category: "Top Alcohol", 
        driver: "Wayne Newby", 
        differential: "Newby 9.5 inch", 
        fastestET: "5.57", 
        fastestMPH: "257.73", 
        website: "http://www.newby.com.au"
    },
    {
        image: "./images/racing/Spinozzi.jpg", 
        title: "Spinozzi Racing", 
        category: "Pro Stock", 
        driver: "Emilio Spinozzi", 
        differential: "Mark Williams Low Drag", 
        fastestET: "7.035", 
        fastestMPH: "194.8", 
        website: "http://www.spinozziracing.com.au"
    },
    {
        image: "./images/racing/Schultz.jpg", 
        title: "Schultz Racing", 
        category: "Modified", 
        driver: "Daniel Schultz", 
        differential: "Yukon 9 inch", 
        fastestET: "7.55", 
        fastestMPH: "181"
    },
    {
        image: "./images/racing/Gauci.jpg", 
        title: "Rick Gauci Racing", 
        category: "Top Alcohol", 
        driver: "Rick Gauci", 
        differential: "Newby 10 inch", 
        fastestET: "5.602", 
        fastestMPH: "254.95", 
        website: "http://www.rickgauciracing.com.au"
    },
    {
        image: "./images/racing/Birrong.jpg", 
        title: "Birrong Automotive", 
        category: "Super Sedan", 
        driver: "Alex Cirtoski", 
        differential: "Lenco 9 1/2 inch", 
        fastestET: "7.74", 
        fastestMPH: "182", 
        website: "http://www.birrongautomotive.com.au"
    }
];

export default cars;