import React, { useState } from "react";
import { testimonials } from "../data/Testimonials";

export const Testimonials = () => {

    const [current, setCurrent] = useState(testimonials[0]);
    const [active, setActive] = useState(0);

    const handleSetClick = (event) => {
        setCurrent(testimonials[event.target.getAttribute("data-quote")]);
        setActive(event.target.getAttribute("data-quote"));
    };

    return(
        <div className="container-xl my-5">
            <h1 className="display-4">Testimonials</h1><div className="form-text mx-3">From our fantastic customers</div>
            <hr className="my-4" />
            <div className="spacer"/>
            <div className="testimonial-container">
            <p>{current.testimonial}</p>
            <p className="text-muted">{current.name}</p>
            <div className="testimonial-nav">
              {Object.keys(testimonials).map(index => (
                <span onClick={event => handleSetClick(event)} data-quote={index} key={index} />
              ))}
            </div>
          </div>
        </div>
    );
}