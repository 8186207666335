import React, { useState } from 'react';
import { Modal } from '../components/Modal';

export const Services = () => {

    const [selected, setSelected] = useState(null);
    const [text, setText] = useState(null);
    const [secondaryImage, setSecondaryImage] = useState(null);

    const textArray = ["We pride ourselves on using quality parts and components when reconditioning gearboxes. Over the years we have learned there’s no substitute for quality (cheaper is not better). We stand by our reconditioned units with a 12 month / 20,000 km warranty.",
    "We pride ourselves on using quality parts and components when reconditioning differentials. Over the years we have learned there’s no substitute for quality (cheaper is not better). We stand by our reconditioned units with a 12 month / 20,000 km warranty.",
    "Upgrading gearboxes for better drivability or to handle more power is a common practice. We can not only help advise you of the best solution but we can also handle all the work and fabrication that a conversion may require.",
    "Upgrading differentials for better drivability or to handle more power is a common practice, we can not only help advise you of the best solution but we can also handle all the work or fabrication that a conversion may require",
    "We can supply and install a wide range of clutches to all vehicles. From Commercial to street and even performance, our experience will help guide you to the best performing clutch to suit your needs whatever they may be.", 
    "To enhance the performance of the clutch and reduce the risk of clutch shudder, the flywheel surface should always be ground to remove all traces of 'hot spots' and maintain the correct flywheel profile. We have the capabilities to machine all flywheels in house to save you time and money.",
    "We have the ability and tooling to straighten axles that have been slightly bent, saving our customers the cost of buying new ones.",
    "Gear lapping is a method of tooth surface finishing on hardened hyoid gears. It can improve the tooth profile accuracy on old worn gears.",
    "It is not always cost effective or viable to purchase new shafts. We have an in-house machine shop and can repair shafts with hardened sleeves to bring them back to manufacturers specifications.",
    "Over our 40+ years of experience we know all the common failures in many gearboxes and diffs. We make it our business to upgrade all the gearboxes and differentials as a 'standard' build, giving our customers the best possible job we can do every time.",
    "We restore old, worn out diffs and gearboxes back to new. By partnering with leading coding specialists, we can easily return that OEM feeling to your ride, and possibly even improve on it."
    ]

    return(
        <div className="container-xl py-5">
            <div className="spacer-half" />
            <h1 className="display-4">Our Services</h1>
            <hr className="my-4"/>
            <div className="spacer" />
            <div className="row align-items-center justify-content-start">
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/gearboxreconditioning.png"); setText(textArray[0]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/gearboxreconditioning.png" alt="gearbox" width="150px" height="150px" />
                    <p className="text-center">Gearbox Reconditioning</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/diffreconditioning.png"); setText(textArray[1]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/diffreconditioning.png" alt="Diff reconditioning" width="150px" height="150px" />
                    <p className="text-center">Differential Reconditioning</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/gearboxconversions.png"); setText(textArray[2]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/gearboxconversions.png" alt="Gearbox Conversions" width="150px" height="150px" />
                    <p className="text-center">Gearbox Conversions</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/diffconversion.png"); setText(textArray[3]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/diffconversion.png" alt="Diff Conversions" width="150px" height="150px" />
                    <p className="text-center">Differential Conversions</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/clutchreplacement.png"); setText(textArray[4]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/clutchreplacement.png" alt="Clutch Replacements" width="150px" height="150px" />
                    <p className="text-center">Clutch Replacements</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/flywheelmachining.png"); setText(textArray[5]);}}> 
                    <img className="d-block mx-auto my-3" src="./images/services/flywheelmachining.png" alt="Flywheel Machining" width="150px" height="150px" />
                    <p className="text-center">Flywheel Machining</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/axlestraightening.png"); setText(textArray[6]);}}> 
                    <img className="d-block mx-auto my-3" src="./images/services/axlestraightening.png" alt="Axle Straigthening" width="150px" height="150px" />
                    <p className="text-center">Axle Straightening</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/gearlapping.png"); setText(textArray[7]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/gearlapping.png" alt="Gear Lapping" width="150px" height="150px" />
                    <p className="text-center">Gear Lapping</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/splinerepair.png"); setText(textArray[8]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/spline.png" alt="Shaft" width="150px" height="150px" />
                    <p className="text-center">Shaft Repairs</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/upgrades.png"); setText(textArray[9]);}}>
                    <img className="d-block mx-auto my-3" src="./images/services/upgrades.png" alt="upgrade" width="150px" height="150px" />
                    <p className="text-center">Part Upgrades</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 service" onClick={() => {setSelected("./images/services/process/restorations.jpg"); setText(textArray[10]); setSecondaryImage("./images/services/process/restorations2.jpg");}}>
                    <img className="d-block mx-auto my-3" src="./images/services/restorations.png" alt="restorations" width="150px" height="150px" />
                    <p className="text-center">Restorations</p>
                </div>
            </div>
            <div className="spacer-half" />
            {selected && 
                <Modal selected={selected} setSelected={setSelected} text={text} secondaryImage={secondaryImage} setSecondaryImage={setSecondaryImage}/>
            }
        </div>
    );
}