import React from "react";

export const Hero = () => {
    return(
        <div className="hero-image">
            <div className="d-flex align-items-center">
                <img src="./images/heroLogo.png" id="heroLogo" className="mt-5" alt="smithfield diff and gearbox" width="600px" height="217.8px" />
            </div>
            <div className="mt-2" id="hero">
                <h2 className="display-8 fw-bold">Our experience is the DIFFerence</h2>
            </div>
            <div className="hero-social">
                <a href="tel:0296048887" id="socialLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-telephone" id="heroPhone"></i></a>
                <a href="sms://+61411072066" id="socialLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-chat-dots-fill" id="heroSMS"></i></a>
                <a href="https://www.facebook.com/SmithfieldDiffandGearbox" id="socialLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook" id="heroFB"></i></a>
                <a href="https://www.instagram.com/smithfielddiffgear/" id="socialLink" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram" id="heroInsta"></i></a>
            </div>
        </div>
    );
}