import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';

export const ContactForm = () => {

    const {register, handleSubmit} = useForm();
    const [data, setData] = useState("");
    const form = useRef();

    const onSubmit = data => {
        console.log(data);
        console.log(data.name);
        
        emailjs.sendForm('service_ki7x6o4', 'template_em28y6j', form.current, 'lteRnzxEi_WQ-F2DK')
        .then((res) => {
            console.log(res.text);
            alert(`Thanks for your message ${data.name}, we will reply to your query as soon as we can!`);
            window.location.reload();
        }, (error) => {
            console.log(error.text);
        });
    }

    return(
        <form ref={form} className="my-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="row align-items-center">
                <div className="col-auto form-floating mb-3" style={{width: "80%"}}>
                    <input className="form-control" {...register("name")} placeholder="Name" style={{width: "100%"}} required />
                    <label className="form-label">Name</label>
                </div>
                <div className="col-auto form-text">Required</div>
            </div>
            <div className="row align-items-center">
                <div className="form-floating mb-3" style={{width: "80%"}}>
                    <input className="form-control" {...register("subject")} placeholder="Subject" style={{width: "100%"}} />
                    <label className="form-label">Subject</label>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="form-floating mb-3" style={{width: "80%"}}>
                    <input className="form-control" {...register("email")} type="email" placeholder="Email" style={{width: "100%"}} />
                    <label className="form-label">Email</label>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-auto form-floating mb-3" style={{width: "80%"}}>
                    <input className="form-control" {...register("mobile")} placeholder="Phone" type="tel" pattern="^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$" style={{width: "100%"}} required/>
                    <label className="form-label">Phone</label>
                </div>
                <div className="col-auto form-text">Required</div>
            </div>
            <div className="row align-items-center">
                <div className="col-auto form-floating mb-3" style={{width: "80%"}}>
                    <textarea className="form-control" {...register("message")} placeholder="Message" style={{height: "100px", width: "100%"}} required/>
                    <label className="form-label">Message</label>
                </div>
                <div className="col-auto form-text">Required</div>
            </div>
            <p>{data}</p>
            <input className="btn btn-primary submitBtn" type="submit" style={{width: "80%"}} />
        </form>
    );
}