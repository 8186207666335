export const testimonials = [
    {
        name: "John Vidal - Western Clutch",
        testimonial: "I John Vidal, owner of Western Clutch Service, have been using Smithfield Diff & Gearbox since I was an apprentice mechanic back in the late 1970’s. At that time Pat, being the father of Raffaele and Anthony, was the owner of the business. In the last 18 or so years when Pat retired, Raffaele and Anthony have taken over the business and with the guidance of their dad in the early years, and with their skill, dedication and knowledge have grown the business and kept it as a highly recommended repairer."
    },
    {
        name: "Andre - DNA Autosport",
        testimonial: "At DNA Autosport we are trusted by our clients to manage the servicing and preparation of their track vehicles. Whether they are entry level builds or highly involved GT cars, we have always trusted the team at Smithfield Diff and Gearbox for the mechanical servicing or repair of our differentials and gearboxes. The team are reliable, experienced and easy to work with, which gives us the ability to produce great results for our customers."
    },
    {
        name: "George Adam - David's Auto Repairs",
        testimonial: "We have had the opportunity to deal with Smithfield Diff & Gear for over 5 years now. Raffaele and Anthony are always professional and trustworthy when it comes to their work. This is a professional and hard working team. Grateful to have their service. The Davids Auto Team"
    },
    {
        name: "Sydney Gazebo and Bali Huts",
        testimonial: "The boys rebuild my chev 12 bolt diff within 1-2weeks. Great service, advise, affordability & knowledge. They cleaned it, painted it and went far and beyond with the entire diff rebuild. Would highly recomend."
    },
    {
        name: "Michael",
        testimonial: "Sent in my vy sv8 to get a new tru trac diff centre and 3.73 gears put in and received a call to tell me it already had 3.73s in it. What mechanic is that honest these days?! I highly recommend these guys! They've done a gearbox for my vk years ago and today they helped me put 2 clean lines on the bitumen. Love your work smithfield diff and gear!"
    },
    {
        name: "Robert Penman",
        testimonial: "After searching through many Sydney basin workshops the gentlemen here have me not only the best price but fantastic service."
    },
    {
        name: "Con Stambo",
        testimonial: "Had a clutch replaced there. Got the car back within a couple of days or so. Excellent customer service, very happy. Great guys as well!"
    },
    {
        name: "Michael Mifsud",
        testimonial: "Great service. They know what they're doing. Best in the industry!"
    },
    {
        name: "Stewart Whicker",
        testimonial: "Great family run business, honest advice means so much in this day and age. Highly recommended."
    },
    {
        name : "PowerTune Australia",
        testimonial: "We use Ralph and Anthony for our Gearbox and Diff repairs. Have been for over 10years. Great service , fair price and in time which is very important for the running of our business also. Highly recommended"
    },
    {
        name: "C & S Diesel",
        testimonial: "Attention to detail is why we choose to use Smithfield Diff & Gear. We have been using them for 18 years as they have professional and precise workmanship and are dedicated to their jobs. They are friendly and accommodating and always go out of their way to help assist us in any way they can. We recommend them highly to any of our customers that might require their specialised diff and gear needs."
    },
    {
        name: "Mick Ryan - Rallyschool Australia",
        testimonial: "We have been using Smithfield Diff and Gear exclusively for our fleet of rally, commercial and personal cars for 15 years. From the very first job on a Tremec 5 speed, the service has always been on time, great quality and a fair price. I have and will continue to recommend the guys to anyone who asks."
    },
    {
        name: "Rapisarda Autosport International",
        testimonial: "For over 10 years, Anf and Raff at Smithfield diff and gear have rebuilt, serviced and maintained the rear ends in all of the Rapisarda Autosport International Top fuel dragsters. It’s nice to have confidence knowing that our rearends have been built and serviced correctly allowing us to compete at the highest level of drag racing. There's no doubt the boys at Smithfield diff and gear have the knowledge and experience to give you a reliable and race winning diff on time, every time."
    },
    {
        name: "Aaron Andreyi",
        testimonial: "Honest, fast, reasonably priced, responsive & open to suggestions about rebuilds. They are equally frustrated about any delay that occurs, which is out of their control. If you’ve lived in Sydney for a while, breaking your diff and going here is possibly more cost effective than a psychologist."
    },
    {
        name: "Orlando Hudson",
        testimonial: "By far best mechanic experience I’ve ever had. Clutch failed just before close on Tuesday. They diagnosed it, ordered the parts and had the new one in and all ready to go midday Thursday. The new clutch was more heavy duty than stock and overall it was substantially cheaper than a quote I got from a another place for a straight stock replacement. They are running a very tight ship here and the guy I spoke to even took the time to make sure everything was really clearly explained. True professionals."
    }
]