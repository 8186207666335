export const partners = [
    {
        partner: "Aisin",
        image: "./images/suppliers/aisinLogo.png",
        alt: "Aisin Corporation"
    },
    {
        partner: "Arp",
        image: "./images/suppliers/arpLogo.png",
        alt: "Automotive Racing Products"
    },
    {
        partner: "ATC",
        image: "./images/suppliers/atcLogo.png",
        alt: "Australian Transmission Components"
    },
    {
        partner: "Capricorn",
        image: "./images/suppliers/capricornLogo.png",
        alt: "Capricorn Preferred Supplier"
    },
    {
        partner: "CR",
        image: "./images/suppliers/crLogo.png",
        alt: "Chicago Rawhide"
    },
    {
        partner: "Cusco",
        image: "./images/suppliers/cuscoLogo.png",
        alt: "Cusco"
    },
    {
        partner: "Dana",
        image: "./images/suppliers/danaLogo.png",
        alt: "Dana"
    },
    {
        partner: "Detroit Locker",
        image: "./images/suppliers/detroitlockerLogo.png",
        alt: "Detroit Locker"
    },
    {
        partner: "Detroit Truetrac",
        image: "./images/suppliers/detroittruetracLogo.png",
        alt: "Detroit Truetrac"
    },
    {
        partner: "Eaton",
        image: "./images/suppliers/eatonLogo.png",
        alt: "Eaton"
    },
    {
        partner: "Exedy",
        image: "./images/suppliers/exedyLogo.png",
        alt: "Exedy Racing Clutches"
    },
    {
        partner: "Gear Drive",
        image: "./images/suppliers/geardriveLogo.png",
        alt: "Gear Drive Systems"
    },
    {
        partner: "Harrop",
        image: "./images/suppliers/harropLogo.png",
        alt: "Harrop Performance"
    },
    {
        partner: "Kaaz",
        image: "./images/suppliers/kaazLogo.png",
        alt: "Kaaz"
    },
    {
        partner: "Koyo",
        image: "./images/suppliers/koyoLogo.png",
        alt: "Koyo"
    },
    {
        partner: "LDS",
        image: "./images/suppliers/ldsLogo.png",
        alt: "Locked Drive Systems"
    },
    {
        partner: "Loctite",
        image: "./images/suppliers/loctiteLogo.png",
        alt: "Loctite"
    },
    {
        partner: "Moser",
        image: "./images/suppliers/moserLogo.png",
        alt: "Moser"
    },
    {
        partner: "Motive",
        image: "./images/suppliers/motiveLogo.png",
        alt: "Motive Gear"
    },
    {
        partner: "Mark Williams",
        image: "./images/suppliers/mwLogo.png",
        alt: "Mark Williams Enterprises"
    },
    {
        partner: "National Oils",
        image: "./images/suppliers/nationaloilsLogo.png",
        alt: "National Oil Seals"
    },
    {
        partner: "Newby",
        image: "./images/suppliers/newbyLogo.png",
        alt: "Newby Engineering"
    },
    {
        partner: "Nismo",
        image: "./images/suppliers/nismoLogo.png",
        alt: "Nismo"
    },
    {
        partner: "NOK",
        image: "./images/suppliers/nokLogo.png",
        alt: "NOK"
    },
    {
        partner: "NSK",
        image: "./images/suppliers/nskLogo.png",
        alt: "NSK"
    },
    {
        partner: "NTN",
        image: "./images/suppliers/ntnLogo.png",
        alt: "NTN"
    },
    {
        partner: "OSGIKEN",
        image: "./images/suppliers/osgikenLogo.png",
        alt: "OS Giken"
    },
    {
        partner: "Penrite",
        image: "./images/suppliers/penriteLogo.png",
        alt: "Penrite"
    },
    {
        partner: "Quaife",
        image: "./images/suppliers/quaifeLogo.png",
        alt: "Quaife"
    },
    {
        partner: "Shannons",
        image: "./images/suppliers/shannonsLogo.png",
        alt: "Shannons Club"
    },
    {
        partner: "SKF",
        image: "./images/suppliers/skfLogo.png",
        alt: "SKF"
    },
    {
        partner: "Spicer",
        image: "./images/suppliers/spicerLogo.png",
        alt: "Spicer Drivetrain Components"
    },
    {
        partner: "Strange",
        image: "./images/suppliers/strangeLogo.png",
        alt: "Strange Engineering"
    },
    {
        partner: "Superior",
        image: "./images/suppliers/superiorLogo.png",
        alt: "Superior Axle & Gear"
    },
    {
        partner: "Terrain Tamer",
        image: "./images/suppliers/terraintamerLogo.png",
        alt: "Terrain Tamer 4WD"
    },
    {
        partner: "Timken",
        image: "./images/suppliers/timkenLogo.png",
        alt: "Timken"
    },
    {
        partner: "Wavetrac",
        image: "./images/suppliers/wavetracLogo.png",
        alt: "Wavetrac Differentials"
    },
    {
        partner: "Western Clutch",
        image: "./images/suppliers/westernclutchLogo.png",
        alt: "Western Clutch Services"
    }
];
export default partners;