import React from "react";

export const Racing = ({image, title, category, driver, differential, fastestET, fastestMPH, website}) => {
    return(
        <div className="card">
            <img src={image} className="card-img-top" alt={driver} />
            <div className="card-body">
                <h5 className="card-title fw-bold">{title}</h5>
                <div className="card-text"><div className="fw-bold">Category: </div>{category}</div>
                <div className="card-text"><div className="fw-bold">Driver: </div>{driver}</div>
                <div className="card-text"><div className="fw-bold">Differential: </div>{differential}</div>
                <div className="card-text"><div className="fw-bold">Fastest ET: </div>{fastestET}</div>
                <div className="card-text"><div className="fw-bold">Fastest MPH: </div>{fastestMPH}</div>
                {website &&
                    <a href={website} className="card-link" target="_blank" rel="noopener noreferrer">{website}</a>
                }
            </div>
        </div>
    );
}