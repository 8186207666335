import React, { useState } from "react";

export const Footer = () => {

    const [mobileFooter, setMobileFooter] = useState(false);

    const changeElements = () => {
        if(window.outerWidth < 800) {
            setMobileFooter(true);
        } else {
            setMobileFooter(false);
        }
    }
    
    window.onload = changeElements;
    window.onresize = changeElements;

    return(
        <div className="container">
            {
                mobileFooter ? (
                <footer className="d-flex flex-wrap justify-content-center align-items-center py-3 my-4 border-top">
                    <div className="col-md-12 d-flex align-items-center">
                        <img className="mb-1 me-2 mb-md-0 mx-2 text-decoration-none lh-1" src="./images/footerLogo.png" alt="Smithfield Diff and Gear" width="110px" height="40px" />
                        <span className="text-muted mx-2 footerCopy">© Smithfield Diff & Gearbox Specialists, PTY LTD</span>
                        <img className="mb-1 me-2 mb-md-0 mx-2 text-decoration-none lh-1" src="./images/suppliers/capricornLogo.png" alt="Smithfield Diff and Gear" width="81.5px" height="50px" />
                    </div>
                    <div className="row mt-4">
                        <ul className="nav col-md-12 d-flex align-items-center list-unstyled">
                            <li className="ms-3">
                                <a className="text-muted footerLink" href="tel:0296048887" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-telephone" id="footerPhone"></i>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="text-muted footerLink" href="sms://+61411072066" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-chat-dots-fill" id="footerSMS"></i>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="text-muted footerLink" href="mailto:anthony@diffandgear.com.au" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-envelope" id="footerMail"></i>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="text-muted footerLink" href="https://www.facebook.com/SmithfieldDiffandGearbox" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-facebook" id="footerFB"></i>
                                </a>
                            </li>
                            <li className="ms-3">
                                <a className="text-muted footerLink" href="https://www.instagram.com/smithfielddiffgear/" target="_blank" rel="noopener noreferrer">
                                    <i className="bi bi-instagram" id="footerInsta"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>
                ) : (
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <div className="col-md-8 d-flex align-items-center">
                        <img className="mb-3 me-2 mb-md-0 mx-2 text-decoration-none lh-1" src="./images/footerLogo.png" alt="Smithfield Diff and Gear" width="110px" height="40px" />
                        <span className="text-muted mx-3 footerCopy">© Smithfield Diff & Gearbox Specialists, PTY LTD</span>
                        <img className="mb-3 me-2 mb-md-0 mx-2 text-decoration-none lh-1" src="./images/suppliers/capricornLogo.png" alt="Smithfield Diff and Gear" width="81.5px" height="50px" />
                    </div>
                    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                        <li className="ms-3">
                            <a className="text-muted footerLink" href="tel:0296048887" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-telephone" id="footerPhone"></i>
                            </a>
                        </li>
                        <li className="ms-3">
                            <a className="text-muted footerLink" href="sms://+61411072066" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-chat-dots-fill" id="footerSMS"></i>
                            </a>
                        </li>
                        <li className="ms-3">
                            <a className="text-muted footerLink" href="mailto:anthony@diffandgear.com.au" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-envelope" id="footerMail"></i>
                            </a>
                        </li>
                        <li className="ms-3">
                            <a className="text-muted footerLink" href="https://www.facebook.com/SmithfieldDiffandGearbox" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-facebook" id="footerFB"></i>
                            </a>
                        </li>
                        <li className="ms-3">
                            <a className="text-muted footerLink" href="https://www.instagram.com/smithfielddiffgear/" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-instagram" id="footerInsta"></i>
                            </a>
                        </li>
                    </ul>
                </footer>)
            }
        </div>
    );
}