import React, { useEffect } from "react";
import { Hero } from "../components/Hero";
import { NavBar } from "../components/NavBar";
import { About } from "./About";

export const Home = (props) => {
    useEffect(() => {
        props.showNav(true);
    });

    return(
        <>
            <Hero />
            <NavBar />
            <About />
        </>
    );
}