import React, { Suspense, lazy, useState } from "react";
import { RandomTestimonial } from "../components/RandomTestimonial";

const ImageGrid = lazy(() => import("../components/ImageGrid"));
const CustomerGrid = lazy(() => import("../components/CustomerGrid"));
const EHGrid = lazy(() => import("../components/EHGrid"));
const Modal = lazy(() => import("../components/Modal"));
const Restorations = lazy(() => import("../components/Restorations"));

export const Gallery = () => {

    const [selected, setSelected] = useState(null);
    const [customerActive, setCustomerActive] = useState("active");
    const [jobActive, setJobActive] = useState("linkColor");
    const [ehActive, setEhActive] = useState("linkColor");
    const [restoActive, setRestoActive] = useState("linkColor");
    const [customerShow, setCustomerShow] = useState("show active");
    const [jobShow, setJobShow] = useState("");
    const [ehShow, setEhShow] = useState("");
    const [restoShow, setRestoShow] = useState("");

    const showCustomer = () => {
        setCustomerActive("active");
        setCustomerShow("active");
        setJobActive("linkColor");
        setJobShow("");
        setEhActive("linkColor");
        setEhShow("");
        setRestoActive("linkColor");
        setRestoShow("");
    }

    const showJob = () => {
        setJobActive("active");
        setJobShow("active");
        setCustomerActive("linkColor");
        setCustomerShow("");
        setEhActive("linkColor");
        setEhShow("");
        setRestoActive("linkColor");
        setRestoShow("");
    }

    const showEH = () => {
        setEhActive("active");
        setEhShow("active");
        setCustomerActive("linkColor");
        setCustomerShow("");
        setJobActive("linkColor");
        setJobShow("");
        setRestoActive("linkColor");
        setRestoShow("");
    }

    const showResto = () => {
        setRestoActive("active");
        setRestoShow("active");
        setCustomerActive("linkColor");
        setCustomerShow("");
        setJobActive("linkColor");
        setJobShow("");
        setEhActive("linkColor");
        setEhShow("");
    }

    return(
        <div className="container-xl py-5">
            <div className="spacer-half"/>
            <h1 className="display-4">Gallery</h1>
            <div className="spacer"/>
            <ul className="nav nav-tabs" id="navTabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${customerActive}`} id="customer-tab" data-bs-toggle="tab" data-bs-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true" onClick={showCustomer}>Customer Gallery</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${jobActive}`} id="job-tab" data-bs-toggle="tab" data-bs-target="#jobs" type="button" role="tab" aria-controls="jobs" aria-selected="true" onClick={showJob}>Jobs Gallery</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${ehActive}`} id="eh-tab" data-bs-toggle="tab" data-bs-target="#eh" type="button" role="tab" aria-controls="eh" aria-selected="true" onClick={showEH}>The Directors' Cars</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={`nav-link ${restoActive}`} id="resto-tab" data-bs-toggle="tab" data-bs-target="#resto" type="button" role="tab" aria-controls="resto" aria-selected="true" onClick={showResto}>Restorations</button>
                </li>
            </ul>
            <div className="tab-content" id="tabContent">
                <div className={`tab-pane ${customerShow}`} id="customer" role="tabpanel" aria-labelledby="customer-tab">
                    <div className="spacer"/>
                    {customerShow.includes("active") ?
                        <Suspense fallback={<p></p>}>
                            <CustomerGrid setSelected={setSelected}/>
                        </Suspense>
                    : null}
                    <div className="spacer"/>
                </div>
                <div className={`tab-pane ${jobShow}`} id="jobs" role="tabpanel" aria-labelledby="jobs-tab">
                    <div className="spacer"/>
                    {jobShow === "active" ?
                        <Suspense fallback={<p></p>}>
                            <ImageGrid setSelected={setSelected}/>
                        </Suspense>
                    : null}
                    <div className="spacer"/>
                </div>
                <div className={`tab-pane ${ehShow}`} id="eh" role="tabpanel" aria-labelledby="eh-tab">
                    <div className="spacer"/>
                    {ehShow === "active" ?
                        <Suspense fallback={<p></p>}>
                            <EHGrid setSelected={setSelected}/>
                        </Suspense>
                    : null}
                    <div className="spacer"/>
                </div>
                <div className={`tab-pane ${restoShow}`} id="resto" role="tabpanel" aria-labelledby="resto-tab">
                    <div className="spacer"/>
                    {restoShow === "active" ?
                        <Suspense fallback={<p></p>}>
                            <Restorations setSelected={setSelected}/>
                        </Suspense>
                    : null}
                    <div className="spacer"/>
                </div>
            </div>
            {selected && 
                <Suspense fallback={<p></p>}>
                    <Modal selected={selected} setSelected={setSelected}/>
                </Suspense>
            }
            <div className="spacer"/>
            <div className="row">
                <RandomTestimonial />
            </div>
            <div className="spacer" />
        </div>
    );
}