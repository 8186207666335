import React from "react";
import { motion } from "framer-motion";

export const Modal = ({selected, setSelected, text, secondaryImage, setSecondaryImage}) => {

    const handleClick = (e) => {
        if(e.target.classList.contains('backdrop')) {
            setSelected(null);
            setSecondaryImage(null);
        }
    }

    return(
        <motion.div className="backdrop" onClick={handleClick} initial={{opacity: 0}} animate={{opacity: 1}}>
            <img src={selected} alt="Smithfield Diff and Gear" loading="lazy" />
            {text && 
            <div className="modalTextContainer">
                {secondaryImage ? 
                    <div className="row">
                        <div className="col-md-4">
                            <img className="modalImage" src={secondaryImage} loading="lazy" />
                        </div>
                        <div className="col-md-8">
                            <p className="text-center modalText">{text}</p>
                        </div>
                    </div>
                : 
                <p className="text-center modalText">{text}</p>
                }
            </div>}
        </motion.div>
    );
};

export default Modal;