import React from "react";
import { testimonials } from "../data/Testimonials";

export const RandomTestimonial = () => {

    const random = Math.floor(Math.random() * testimonials.length);
    const randomTestimonial = testimonials[random];

    return(
        <div className="container-xl align-items-center my-5">
            <div className="col-md-8 mx-auto my-3">
                {randomTestimonial && 
                    <div className="px-3 py-3">
                        <div className="my-2 py-2">
                            <p className="text-center">{randomTestimonial.testimonial}</p>
                        </div>
                        <div className="my-2 py-2">
                            <p className="text-muted text-center">{randomTestimonial.name}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}